export default class NewsletterController {

  constructor($scope, $http){
    this.$scope = $scope;
    this.$http = $http;

    var ctrl = this;
    ctrl.success = false;
    ctrl.error = false;
    ctrl.email = null;
    ctrl.disabled = false;
  }


  submit(){

    var ctrl = this;
    ctrl.success = false;
    ctrl.error = false;
    ctrl.disabled = true;

    ctrl.$http({url: '/newsletter/subscribe',
                  method: 'POST',
                  data:{email: ctrl.email}
                })
        .then(function(response){
          ctrl.success = true;
          ctrl.disabled = false;
        })
        .catch(function(error){
          ctrl.error = error.data.message;
          ctrl.disabled = false;
        });
  }

};

NewsletterController.$inject = ['$scope', '$http'];
