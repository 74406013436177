export default class FileUploadService{

  constructor($http, S3BlobChunkService) {
    this.$http = $http;
    this.S3BlobChunkService = S3BlobChunkService;
    this.baseLang = document.location.pathname.substr(0,3) == "/en" ? "/en" : "";
    this.partSize = 20 * 1024 * 1024; // Chunks of 20 Mb
    this.multipartKeys;
    this.progressCallback;
    this.simultaneousChunks = 4;
    this.file;
    this.fileSize;
    this.numParts = 0;
    this.maxChunkRetries = 3;
    this.queue = {
      start : false,
      blobs : {},
      active : 0,
      last : 0,
      complete: 0,
      total : 0,
      failed : 0
    };
  }

  initiate(video_id, formData) {
    var $upload = this;
    const url = $upload.baseLang + '/ajax/video/upload/'+video_id;
    return $upload.$http.post(url, formData, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined},
    }).then(x => {
      return x.data
    });
  }

  initiateEpisode(serie_id, formData) {
    var $upload = this;
    const url = $upload.baseLang + '/ajax/episode/upload/'+serie_id;
    return $upload.$http.post(url, formData, {
      transformRequest: angular.identity,
      headers: {'Content-Type': undefined},
    }).then(x => {
      return x.data
    });
  }

  /**
   * Setup the partions
   */
  startPartitioning(file) {
    var $upload = this;

    $upload.fileSize = file.size;
    $upload.file = file;

    const totalParts = $upload.fileSize / $upload.partSize;
    const totalFloor = Math.floor(totalParts);

    // Make sure it's never zero parts
    if (totalFloor <= 0) {
      $upload.numParts = 1;
    // If the last chunk is small, add it to the last big chunk instead
    } else if ((totalParts - totalFloor) < 0.2) {
      $upload.numParts = totalFloor;
    // Else round it up
    } else {
      $upload.numParts = Math.ceil(totalParts);
    }

    var i;
    for (i = 1; i <= $upload.numParts; i++) {
      $upload.preparePart(i, (i == $upload.numParts));
    }

    $upload.queue.start = true;
  }

  /**
   * Prepares the details of each chunk for the queue
   */
  preparePart(partNum, lastPart) {
    var $upload = this;
    const start = (partNum - 1) * $upload.partSize;

    // Last chunk always ends with the file size
    if (lastPart) {
      var end = $upload.fileSize;
    } else {
      var end = start + $upload.partSize;
    }

    // How much is going up this time
    const length = end - start;

    // Add it to the queue
    $upload.queue.total++;
    $upload.queue.blobs[partNum] = {
      start   : start,
      end     : end,
      length  : length,
      last    : lastPart
    };
  }

  /**
   * Dispatch the next part of the upload
   * Spawns instances of the S3BlobChunk
   */
  queueDespatchNext(resolve, reject) {
    var $upload = this;

    // Something failed so stop uploading
    if ($upload.queue.failed > 0) {
      reject(new Error('Téléchargement échoué (QDN01)'));
    }

    // queue is stopped
    if (!$upload.queue.start) {
      reject(new Error('Téléchargement échoué (QDN02)'));
    }

    var despatching = $upload.queue.last + 1;

    // No parts left
    if (despatching > $upload.numParts && $upload.queue.active === 0) {
      resolve();
      return;
    }

    if (!$upload.queue.blobs[despatching]) {
      return;
    }

    // Info on what's being uploaded
    var blob        = $upload.queue.blobs[despatching];
    var curBlobPart = $upload.file.slice(blob.start, blob.end);

    // New Chunk objects
    // console.log('Trying to send ' + despatching);
    $upload.retrySend($upload.multipartKeys, curBlobPart, despatching, $upload.maxChunkRetries).then(() => {
      $upload.queue.active--;
      $upload.queue.complete++;
      $upload.queueDespatchNext(resolve, reject);
      $upload.progressCallback(Math.round($upload.queue.complete / $upload.numParts * 100));
    }).catch((e) => {
      console.log('Error sending despatch ' + despatching + ', abort');
      // $upload.queue.failed++;
      reject(e);
    });

    $upload.queue.last = despatching;

    // Up the count
    $upload.queue.active++;

    // Send another part if not completely active
    if ($upload.queue.active < $upload.simultaneousChunks) {
      $upload.queueDespatchNext(resolve, reject);
    }
  }

  retrySend(multipartKeys, blob, despatching, retries) {
    var $upload = this;

    console.log(retries + ' retries left for despatch ' + despatching);

    return new Promise((resolve, reject) => {
      $upload.S3BlobChunkService.send(multipartKeys, blob, despatching)
        .then(resolve)
        .catch((reason) => {
          if (retries > 0) {
            return $upload.retrySend(multipartKeys, blob, despatching, retries - 1)
              .then(resolve)
              .catch(reject)
            ;
          }
          return reject(reason);
        });
    })
  }

  queueDespatchAll() {
    var $upload = this;
    return new Promise((resolve, reject) => {
      $upload.queueDespatchNext(resolve, reject)
    })
  }

  /**
   * We Finished. Hooray!
   */
  completeMultipartUpload() {
      var $upload = this;

      // So something failed so stop uploading
      if ($upload.queue.failed > 0) {
        throw new Error('Téléchargement échoué (queue failed)');
      }

      // queue is stopped
      if (!$upload.queue.start) {
        throw new Error('Téléchargement échoué (queue stopped)');
      }

      const url = $upload.baseLang + '/ajax/video/complete/';
      return $upload.$http.post(url, JSON.stringify({ key: $upload.multipartKeys.key, uploadId: $upload.multipartKeys.uploadId }), {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
          },
      }).then(function(r) {
        return r.data;
      });
  }

  uploadMultipart(multipartKeys, file, progressCallback) {
    var $upload = this;

    $upload.queue = {
      start   : false,
      blobs   : {},
      active  : 0,
      last    : 0,
      complete: 0,
      total: 0,
      failed  : 0
    };
    $upload.multipartKeys = multipartKeys;
    $upload.progressCallback = progressCallback;
    $upload.startPartitioning(file);

    return $upload.queueDespatchAll().then(() => {
      return $upload.completeMultipartUpload();
    });
  }

  upload(postUrl, formData, progressCallback) {
      var $upload = this;
      // Delete the Requested With Header
      //delete $upload.$http.defaults.headers.common['X-Requested-With'];

      return $upload.$http.post(postUrl, formData, {
          transformRequest: angular.identity,
          headers: {
            'Content-Type': undefined,
            // 'Origin': 'http://festival-nikon.local',
            // 'Access-Control-Request-Method': 'POST',
            // 'Access-Control-Request-Headers': 'Content-Type, Authorization' // https://the.sign_in.url
          },
          uploadEventHandlers: {
            progress: function(progressEvent) {
              var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total )
              progressCallback(percentCompleted)
            }
          }
      });
  }

  check(video_id){
    var $upload = this;
    const url = $upload.baseLang + '/ajax/video/check/'+video_id;
    return $upload.$http.post(url, {}, {}).then(function(x){
      return x.data
    });
  }

  checkEpisode(serie_id){
    var $upload = this;
    const url = $upload.baseLang + '/ajax/episode/check/'+serie_id;
    return $upload.$http.post(url, {}, {}).then(function(x){
      return x.data
    });
  }
}

FileUploadService.$inject = ['$http', 'S3BlobChunkService'];
