export default class OnFileChange{
  constructor($timeout) {
    this.$timeout = $timeout;
    this.restrict = 'A';
  }

  link(scope, element, attrs) {
    var that = this;
    var onChangeHandler = scope.$eval(attrs.onFileChange);
    element.on('change', function(event){
      that.$timeout(function(){

        onChangeHandler(event.target.files[0])
      });
    });
    element.on('$destroy', function() {
      element.off();
    });

  }
};


OnFileChange.$inject = ["$timeout"];
