export default class DirectorController {
  constructor($scope, $uibModal, $http, $timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;
  }

  openThumbnailModal(video_id) {
    var ctrl = this;
    ctrl.$uibModal.open({
      animation: false,
      templateUrl: 'thumbnail-update-' + video_id + '.html',
      windowClass: 'thumbnail-update',
      size:"md",
      backdrop:"static",
      controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
        $scope.cancel = function(){
          $uibModalInstance.dismiss();
        }
      }]
    }).result.then(function(){}, function(res){});
  }

  openShareEmbedModal(video_id){
    var ctrl = this;
    ctrl.$uibModal.open({
      animation: false,
      templateUrl: 'share-video-' + video_id + '.html',
      windowClass: "archive",
      size:"md",
      controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
        $scope.cancel = function(){
          $uibModalInstance.dismiss();
        }
      }]
    });
  }
};

DirectorController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
