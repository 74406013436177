require("../../scss/front/app.scss");
require("./modernizr.js");
//polyfills
import "@babel/polyfill";

const $ = require("jquery");
window.$ = $;
var jQueryBridget = require("jquery-bridget");
require("ev-emitter"),
  require("desandro-matches-selector"),
  require("fizzy-ui-utils");
require("get-size");
require("outlayer");

var imagesLoaded = require("imagesloaded");
imagesLoaded.makeJQueryPlugin($);

// // "node_modules/outlayer/item.js",
// // "node_modules/outlayer/outlayer.js",
// // "node_modules/masonry-layout/dist/masonry.pkgd.js",
// require("imagesloaded");
import angular from "angular";
require("angular-ui-bootstrap");
var Masonry = require("masonry-layout");
// make Masonry a jQuery plugin
jQueryBridget("masonry", Masonry, $);
require("angular-masonry");

require("slick-carousel");
require("angular-slick-carousel");
require("ui-cropper");

// const humanizeDuration = require("humanize-duration");
// require("moment");
require("moment/locale/fr.js");
require("angular-timer");

// "node_modules/fg-loadjs/loadJS.js",
// "node_modules/svg4everybody/dist/svg4everybody.js"
//"node_modules/moment/moment.js"
//"node_modules/moment/locale/fr.js"
//"node_modules/humanize-duration/humanize-duration.js"

// "node_modules/slick-carousel/slick/slick.js",

/* global */

// from homeController

var bgParallaxTargets = ["#participate", "#prices"];
var bgParallaxSizes = {};
var introParallaxTargets = [];

function getBackgroundImageSize(selector) {
  var target = $(selector);
  var imageSrc = target.css("background-image");
  if (!imageSrc) return;
  imageSrc = imageSrc.replace(/url\((['"])?(.*?)\1\)/gi, "$2").split(",")[0];
  var image = new Image();
  image.onload = function () {
    var width = image.width,
      height = image.height;
    bgParallaxSizes[selector] = {
      width: width,
      height: height,
    };
  };
  image.src = imageSrc;
}

function clamp(val, min, max) {
  return Math.max(min, Math.min(max, val));
}

function handleBgParallax(selector) {
  var target = $(selector);
  var viewportHeight = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0
  );
  var viewportCenter = (viewportCenter = viewportHeight / 2);
  var imgSize = bgParallaxSizes[selector];
  if (!imgSize) return;
  var ratio = target.width() / target.height();
  var bgHeight = null;
  if (ratio >= 1) {
    bgHeight = (target.width() * imgSize.height) / imgSize.width;
  } else {
    target.css("background-position", "");
    return;
  }
  var bgOverflow = bgHeight - target.height();
  if (bgOverflow <= 0) {
    target.css("background-position", "");
    return;
  }
  var halfBgOverflow = bgOverflow / 2;

  if (target.position().top == 0) {
    var bbox = target[0].getBoundingClientRect();
    var bgScroll = clamp(bbox.top, -bgOverflow, 0);
  } else {
    var bbox = target[0].getBoundingClientRect();
    var bboxCenter = bbox.top + target.height() / 2;
    var bboxCenterOffset = bboxCenter - viewportCenter;

    var bgScroll =
      clamp(bboxCenterOffset, -halfBgOverflow, halfBgOverflow) - halfBgOverflow;
  }
  target.css("background-position", "0 " + bgScroll + "px");
}

/*
function handleIntroParallax(selector){
  var target = $(selector);
  var height = target.height();
  var delta = height * 0.2;
  var top = ($(document).scrollTop() *.4) - delta/2;
  var translation = Math.min(delta/2, top);
  target.css("transform", "scale(1.2) translate(0, "+ -translation +"px)")
  target.find(".container-fluid").css("transform", "scale(.833) translate(0, "+ translation +"px)")
}
*/

$(function () {
  /*
  $("#intro .slide-item .intro-slide").each(function(i,e){
    introParallaxTargets.push("#intro .slide-item:nth-child("+(i+1)+") .intro-slide")
  })
*/
  for (var i = 0; i < bgParallaxTargets.length; i++) {
    var selector = bgParallaxTargets[i];
    getBackgroundImageSize(selector);
  }

  $(document).trigger("scroll");
});

$(document).on("scroll", function (evt) {
  /*for(var i=0; i< introParallaxTargets.length; i++){
     var selector = introParallaxTargets[i];
     handleIntroParallax(selector);
   }*/
  for (var i = 0; i < bgParallaxTargets.length; i++) {
    var selector = bgParallaxTargets[i];
    handleBgParallax(selector);
  }
  /* repeated on enter view animation */
  $(
    ".section-title, .filters, .home-selection a.see-all, #intro .title-container, section.intro .countdown-container,  #main-menu .navbar, .button.participate, section.jury, section.prices, section.prices #prices-carousel .carousel-indicators"
  ).each(function (i, e) {
    var bbox = e.getBoundingClientRect();
    var viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    if (bbox.top <= viewportHeight && bbox.bottom >= 0) {
      $(e).addClass("in-view");
    } else {
      $(e).removeClass("in-view");
    }
  });

  /* one timers */
  $("#header, .iam").each(function (i, e) {
    var bbox = e.getBoundingClientRect();
    var viewportHeight = Math.max(
      document.documentElement.clientHeight,
      window.innerHeight || 0
    );
    if (bbox.top < viewportHeight && bbox.bottom > 0) {
      $(e).addClass("in-view");
    }
  });
});
// end from homeController

// end globals

// VIDEO TEASER
function setTeaserVideoSource() {
  var vid = document.getElementById("video-bg");
  var vidContainer = document.getElementById("video-bg-container");
  if (vid === null) {
    return;
  }

  var ratio = window.innerWidth / window.innerHeight;
  var source = document.createElement("source");
  source.setAttribute("type", "video/mp4");
  vid.appendChild(source);

  vid.pause();
  source.removeAttribute("src");

  if (ratio <= 1.2) {
    $(vidContainer).addClass("hidden");
  } else {
    $(vidContainer).removeClass("hidden");
    source.setAttribute("src", "img/design/teaser/NFF14-TEASER-16-9.mp4");
  }

  vid.load();
  vid.play();
}
// setTeaserVideoSource();
// VIDEO TEASER

// controllers
import AnnonceController from "./controllers/annonceController";
import ArchiveIndexController from "./controllers/archiveIndexController";
import ArchiveItemController from "./controllers/archiveItemController";
import DirectorController from "./controllers/directorController";
import FaqController from "./controllers/faqController";
import GlobalController from "./controllers/globalController";
import HomeController from "./controllers/homeController";
import NewsletterController from "./controllers/newsletterController";
import RegisterController from "./controllers/registerController";
import UploadController from "./controllers/uploadController";
import UploadEpisodeController from "./controllers/uploadEpisodeController";
import UploadPosterController from "./controllers/uploadPosterController";
import UserController from "./controllers/userController";
import SeriesController from "./controllers/seriesController";
import SerieController from "./controllers/serieController";
import VideoController from "./controllers/videoController";
import VideosController from "./controllers/videosController";


// directive
import OnFileChange from "./directives/fileChange";
import LimitedLengthInput from "./directives/limitedLengthInput";

// services
import FileUploadService from "./services/file-upload-service";
import S3BlobChunkService from "./services/s3-blob-chunk-service";

var app = angular
  .module("nikon-ff9", [
    "timer",
    "ui.bootstrap",
    "wu.masonry",
    "slickCarousel",
    "uiCropper",
  ])
  .controller("annonceController", AnnonceController)
  .controller("archiveItemController", ArchiveItemController)
  .controller("archiveIndexController", ArchiveIndexController)
  .controller("faqController", FaqController)
  .controller("globalController", GlobalController)
  .controller("homeController", HomeController)
  .controller("newsletterController", NewsletterController)
  .controller("registerController", RegisterController)
  .controller("uploadController", UploadController)
  .controller("uploadPosterController", UploadPosterController)
  .controller("videoController", VideoController)
  .controller("videosController", VideosController)
  .controller("seriesController", SeriesController)
  .controller("serieController", SerieController)
  .controller("directorController", DirectorController)
  .controller("userController", UserController)
  .controller("uploadEpisodeController", UploadEpisodeController)
  .directive("onFileChange", [
    "$timeout",
    ($timeout) => new OnFileChange($timeout),
  ])
  .directive("limitedLengthInput", [() => new LimitedLengthInput()])
  .service("fileUploadService", FileUploadService)
  .service("S3BlobChunkService", S3BlobChunkService);
app.config([
  "$interpolateProvider",
  function ($interpolateProvider) {
    $interpolateProvider.startSymbol("{$");
    $interpolateProvider.endSymbol("$}");
  },
]);

app.run([
  "$rootScope",
  "$window",
  function ($rootScope, $window) {
    $rootScope.user = {};
  },
]);

angular.module("controllers", ["ngSanitize"]);
angular.module("directives", []);
