export default class ArchiveItemController {

  constructor($scope, $uibModal){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.prices = {};
    this.current = null;
  }

  addVideo(video, type, dm_id){
    var ctrl = this;
    ctrl.prices[video.id] = video;
    ctrl.prices[video.id].type = type;
    ctrl.prices[video.id].dm_id = dm_id;

    if(ctrl.current == null){
      ctrl.current = ctrl.prices[video.id];
    }
  }

  chooseItem(chosen){
    var ctrl = this;
    ctrl.current = ctrl.prices[chosen];
  }

  modal(url){
    var ctrl = this;
    ctrl.$uibModal.open({
        animation: false,
        templateUrl: "archiveVideo.html",
        windowClass: "archive",
        size:"md",
        controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
          $scope.archive = url;
          $scope.cancel = function(){
            $uibModalInstance.dismiss();
          }
        }]
    });

  }

};

ArchiveItemController.$inject = ['$scope', '$uibModal'];
