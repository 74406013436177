export default class S3BlobChunkService {
  constructor($http) {
    this.$http = $http;
    this.baseLang = document.location.pathname.substr(0,3) == '/en' ? '/en' : '';
  }

  send(keys, blob, blobNum) {
    var $upload = this;

    return $upload.signChunk(keys, blob, blobNum).then(r => {
      return $upload.sendChunk(r.data.url, blob);
    });
  }

  signChunk(keys, blob, blobNum) {
    var $upload = this;

    const params = {
      partNumber: blobNum,
      contentLength: blob.size,
      model: {
        key: keys.key,
        uploadId: keys.uploadId
      }
    };

    return $upload.$http.post($upload.baseLang + '/ajax/video/sign/', JSON.stringify(params), {
      transformRequest: angular.identity,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
    });
  }

  sendChunk(url, blob) {
    // test : fail une fois sur 3
    // if (Math.floor(Math.random() * 3) == 2) {
    //   throw new Error('sendChunk FAIL');
    // }

    var $upload = this;

    return $upload.$http.put(url, blob, {
      transformRequest: angular.identity,
      headers: {
        'Content-Type': undefined,
      }
    });
  }
}

S3BlobChunkService.$inject = ['$http'];
