export default class LimitedLengthInput{
  constructor() {
    this.transclude = true;
    this.scope = {
      "lengthLimit": "=",
      "input":"@"
    }
  }

  link(scope, element, attrs, ctrl, transclude) {
    transclude(scope, function(clone) {
      element.append(clone);
     });

  }
};


LimitedLengthInput.$inject = [];
