export default class globalController{

  constructor($scope, $rootScope, $uibModal, $http,$timeout, $window){
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;
    this.$window = $window;
    var $gctrl = this;
    $gctrl.$scope.is_mobile = $window.innerWidth < 1001;
    $gctrl.open_nl_modal = false;

    document.querySelector('body').setAttribute('data-iswebview', $gctrl.isWebView());

    angular.element($window).bind('resize', function(){
      $gctrl.$scope.is_mobile = $window.innerWidth < 1001;
       // manuall $digest required as resize event
       // is outside of angular
       $gctrl.$scope.$digest();
     });

    $gctrl.$postLink = function() {
      if ($gctrl.open_nl_modal) {
        this.openNewlsetterOptinModal();
      }
    };
  }

  openNewlsetterOptinModal() {
    this.$uibModal.open({
      animation: false,
      templateUrl: "newsletter-optin-modal.html",
      windowClass: "facebook-optin",
      size:"md",
      backdrop:"static",
      controller: ["$uibModalInstance", "$scope", "$http", function($uibModalInstance, $scope, $http) {
      $scope.no = function(){
          $uibModalInstance.close(false);
      }
      $scope.yes = function(){
          $http({
            url: '/json/ajaxnloptin',
            method: 'POST'
          }).then(function(){
            $uibModalInstance.close(true);
          })
      }
      }]
    }).result.then(function(){}, function(res){});
  }

  isWebView() {
    var ua = navigator.userAgent || navigator.vendor || window.opera;
    var isFBApp = (ua.indexOf('FBAN') > -1) || (ua.indexOf('FBAV') > -1);
    var isInstaApp = (ua.indexOf('Instagram') > -1);
    return (isFBApp || isInstaApp);
  }
};

globalController.$inject = ['$scope', '$rootScope', '$uibModal', '$http', '$timeout', '$window'];
