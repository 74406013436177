export default class UserController {
  constructor($scope, $uibModal) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
  }

  openDeleteModal() {
    const modalInstance = this.$uibModal.open({
      animation: false,
      templateUrl: "delete-confirmation.html",
      windowClass: "delete-confirmation",
      size: "md",
      backdrop: "static",
      controller: [
        "$uibModalInstance",
        "$scope",
        function ($uibModalInstance, $scope) {
          $scope.confirmDelete = false;

          $scope.cancel = function () {
            $uibModalInstance.dismiss("cancel");
          };

          $scope.confirm = function () {
            if ($scope.confirmDelete) {
              const deleteForm = document.getElementById("delete-form");
              if (deleteForm && deleteForm instanceof HTMLFormElement) {
                deleteForm.submit();
              } else {
                console.error("Aucun formulaire de suppression");
              }
            }
          };

          $scope.$watch("confirmDelete", function (newValue) {
            const confirmDeleteButton = document.getElementById(
              "confirm-delete-button"
            );
            if (
              confirmDeleteButton &&
              confirmDeleteButton instanceof HTMLButtonElement
            ) {
              confirmDeleteButton.disabled = !newValue;
            }
          });
        },
      ],
    });

    modalInstance.rendered
      .then(() => {
        const closeModalButton = document.getElementById("close-modal-button");
        const cancelButton = document.getElementById("cancel-delete-button");
        const confirmDeleteCheckbox = document.getElementById(
          "confirm-delete-checkbox"
        );
        const confirmDeleteButton = document.getElementById(
          "confirm-delete-button"
        );

        if (closeModalButton) {
          closeModalButton.addEventListener("click", (event) => {
            event.preventDefault();
            modalInstance.dismiss("close");
          });
        }

        if (cancelButton) {
          cancelButton.addEventListener("click", (event) => {
            event.preventDefault();
            modalInstance.dismiss("cancel");
          });
        }

        if (
          confirmDeleteCheckbox &&
          confirmDeleteCheckbox instanceof HTMLInputElement
        ) {
          confirmDeleteCheckbox.addEventListener("change", () => {
            if (
              confirmDeleteButton &&
              confirmDeleteButton instanceof HTMLButtonElement
            ) {
              confirmDeleteButton.disabled = !confirmDeleteCheckbox.checked;
            }
          });
        }
      })
      .catch((error) => {
        console.error("Erreur lors de l'affichage du modal:", error);
      });

    modalInstance.result.catch((error) => {
      if (error !== "cancel" && error !== "close") {
        console.error("Modal a été fermé avec une erreur:", error);
      }
    });
  }
}

UserController.$inject = ["$scope", "$uibModal"];
