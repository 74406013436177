export default class RegisterController {

  constructor($scope, $uibModal, $http, $timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;

    var handleFileSelect=function(evt) {
        var file=evt.currentTarget.files[0];
        var reader = new FileReader();

        var imageError = (file.size > 8 * 1024 * 1024);
        $scope.$apply(function($scope){
            $scope.imageError = imageError;
        });
        if(imageError){
          document.querySelector('#avatarUpload').value="";
          return;
        }

        $scope.myImage='';
        $scope.myCroppedImage='';

        reader.onload = function (evt) {
          $scope.$apply(function($scope){
            $scope.myImage=evt.target.result;
          });
        };

        reader.readAsDataURL(file);
    };
    angular.element(document.querySelector('#avatarUpload')).on('change',handleFileSelect);
  }

  prefill(){
    var $ctrl = this;
    var rand_num = parseInt(Math.random() * 1000) + 1;
    $('input[name=first_name]').val("prenom-test" + rand_num);
    $('input[name=last_name]').val("nom test " + rand_num);
    $('input[name=email]').val("test" + rand_num + "@test.com");
    $('input[name=phone]').val("0123456789");
    $('input[id=password]').val("password");
    $('input[id=confirm]').val("password");
    $('input[id=day]').val((rand_num % 28) + 1 );
    $('input[id=month]').val((rand_num % 12) + 1);
    $('input[id=year]').val(1900 + rand_num % 110);
    $('input[name=address]').val(rand_num + " rue de neuilly");
    $('input[name=city]').val("Clichy");
    $('select[name=gender]').val("M");
    $('input[name=zip]').val(92000 + rand_num % 900);
    $('input[name=pseudo]').val("pseudo" + rand_num);
    $('input[id=agreed]').attr('checked', true);
    $('html,body').animate({scrollTop: $('html,body').height()}, 100);
  }

  prefillUsername() {
    $('input[name=pseudo]').val(($('input[name=first_name]').val() + ' ' + $('input[name=last_name]').val()).trim());
  }

  register(){

  }
};

RegisterController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
