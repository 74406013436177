
export default class AnnonceController{

  constructor($scope, $uibModal, $http, $timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;
    this.noResults = false;
    this.criteria = {'type':'', 'dept':''};
  }

  // var ctrl = this;

  annonceAddModal() {
    var ctrl = this;
    ctrl.$uibModal.open({
      animation: false,
      templateUrl: "addAnnonce.html",
      windowClass: "annonce",
      size:"md",
      backdrop:"static",
      controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
        $scope.cancel = function(){
          $uibModalInstance.dismiss();
        }
      }]
    }).result.then(function(){}, function(res){});
  }

  contactModal(id) {
    var ctrl = this;
    ctrl.$uibModal.open({
      animation: false,
      templateUrl: "contact.html",
      windowClass: "annonce",
      size:"md",
      backdrop:"static",
      controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
        $scope.contactFormData = {id: id};
        $scope.cancel = function(){
          $uibModalInstance.dismiss();
        }
      }]
    }).result.then(function(){}, function(res){});
  }


  filter(crit, value) {
    var ctrl = this;
    console.log("this", this);
    console.log("this", this.criteria);
    if(crit == "dept"){
      ctrl.criteria.dept = ctrl.$scope.dept;
    }else{
      if(ctrl.criteria.type == value){
        ctrl.criteria.type = '';
      }else{
        ctrl.criteria.type = value;
      }
    }
    console.log(ctrl.criteria);

    // if (crit == 'type')
    //   $('#annonces').attr('data-type', $('#annonces').attr('data-type') == value ? '' : value);
    // else if (crit == 'dept')
    //   $('#annonces').attr('data-dept', $scope.dept);

    // let selector = '#annonces .annonce-wrapper';

    // $(selector).hide();

    // if ($('#annonces').attr('data-type') != '')
    //   selector += '[data-type="' + $('#annonces').attr('data-type') + '"]';
    // if ($('#annonces').attr('data-dept') != '')
    //   selector += '[data-dept="' + $('#annonces').attr('data-dept') + '"]';

    // $(selector).show();
    // ctrl.noresults = ($(selector).length == 0);
    ctrl.$timeout(function(){
        ctrl.$scope.$broadcast('masonry.reload', {});
    },500);

    // $('#annonces').masonry();
  }


  addAnnonceFormSubmit() {
    var ctrl = this;
    ctrl.$http({
      method  : 'POST',
      url     : '/annonce/new',
      data    : $.param(ctrl.$scope.annonceAddFormData),  // pass in data as strings
      headers : { 'Content-Type': 'application/x-www-form-urlencoded' }  // set the headers so angular passing info as form data (not request payload)
    })
    .then(function(response){
      ctrl.$scope.addAnnonceFormConfirm = true;
    })
    .catch(function(error){
    });
  }

  contactFormSubmit() {
    var ctrl = this;
    ctrl.$http({
      method  : 'POST',
      url     : '/annonce/contact',
      data    : $.param(ctrl.$scope.contactFormData),  // pass in data as strings
      headers : { 'Content-Type': 'application/x-www-form-urlencoded' }  // set the headers so angular passing info as form data (not request payload)
    })
    .then(function(response){
      ctrl.$scope.contactFormConfirm = true;
    })
    .catch(function(error){
    });
  }

}

AnnonceController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
