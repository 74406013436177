export default class SeriesController {
    constructor($scope, $uibModal, $http, $timeout){
      this.$scope = $scope;
      this.$uibModal = $uibModal;
      this.$http = $http;
      this.$timeout = $timeout;
  
      var ctrl = this;
      ctrl.offset = 20;
      ctrl.limit  = 20;
    }
  
  
    more(mode, salt, limit, offset){
      var ctrl = this;
      var params= {
          limit: limit || ctrl.limit,
          offset: offset || ctrl.offset,
          mode: mode
      }
  
      $("#see-more").hide();
  
      if(typeof(salt) != "undefined"){
        params.salt = salt;
      }
        ctrl.$http({
              url: '/json/series',
              params: params,
        }).then(function(resp, status, headers){
          $("#top50").append(resp.data);
          ctrl.offset = ctrl.offset + ctrl.limit;
  
          if(resp.data.trim() != "" && resp.headers()["x-has-more"] == "1"){
            $("#see-more").show();
          }
  
          if(resp.headers()["x-list-title"]){
            $("serie-list-title").html(resp.headers()["x-list-title"]);
          }
        })
    }
  
  };
  
  SeriesController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
  