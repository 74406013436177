export default class HomeController{
  constructor($scope, $uibModal, $http,$timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;
    var ctrl = this;

    $scope.carouselVideoSettings = {
      dots: true,
      arrows: false
    }

    $scope.carouselJurySettings = {
      dots: true,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev"><img src="/img/icons/arrow-left.png" /></button>',
      nextArrow: '<button type="button" class="slick-next"><img src="/img/icons/arrow-right.png" /></button>'
    }

    $scope.carouselSelectionSettings = {
      dots: true,
      arrows: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    }

  }
}

HomeController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
