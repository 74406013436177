const STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default class UploadPosterController {
  constructor($scope, $uibModal, $http, $timeout, fileUploadService) {
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;
    this.fileUploadService = fileUploadService;
    var $upload = this;

    $upload.baseLang =
      document.location.pathname.substr(0, 3) == "/en" ? "/en" : "";

    $upload.currentStatus = null;

    $upload.allowed = false;
    $upload.progress = 0;
    $upload.file = null;
    $upload.processing = false;
    $upload.error_code = "0";

    var dropbox = angular.element(".upload-form")[0];
    dropbox.addEventListener(
      "dragenter",
      $upload.cancelEvent.bind($upload),
      false
    );
    dropbox.addEventListener(
      "dragexit",
      $upload.cancelEvent.bind($upload),
      false
    );
    dropbox.addEventListener(
      "dragover",
      $upload.cancelEvent.bind($upload),
      false
    );
    dropbox.addEventListener("drop", $upload.droppedFile.bind($upload), false);
  }

  isSuccess() {
    return this.currentStatus === STATUS_SUCCESS;
  }
  isFailed() {
    return this.currentStatus === STATUS_FAILED;
  }

  cancelEvent(e) {
    e.stopPropagation();
    e.preventDefault();
  }

  droppedFile(evt) {
    var $upload = this;
    $upload.cancelEvent(evt);
    var files = evt.dataTransfer.files;
    var count = files.length;
    if (count > 1) {
      $upload.$timeout(function () {
        $upload.error_code = "too_many_files";
      });
      return;
    }
    $upload.$timeout(function () {
        $upload.fileChosen(files[0], $upload);
        
        const fileInput = document.getElementById('form-file');
        if (fileInput) {
          const event = new Event('change', { bubbles: true });
          fileInput.dispatchEvent(event);
        }
    });
  }

  fileChosen(file, $upload) {
    $upload = $upload || this;

    $upload.file = file;
    var filename = $upload.file.name;
    var fileext = filename.substr(filename.lastIndexOf(".")).toLowerCase();

    console.log($upload.file, "chosen");

    $upload.error_code = null;
    $upload.error_message = null;
    $upload.progress = 0;

    var allowedExtensions = [".jpg", ".jpeg", ".png"];
    if (allowedExtensions.indexOf(fileext) == -1) {
      $upload.error_code = "file_ext";
      console.error($upload.error_code);
      return;
    }
    if ($upload.file.size > 10 * 1024 * 1024) {
      // 5 Mo
      $upload.error_code = "file_size";
      console.error($upload.error_code);
      return;
    }

    const fileInput = document.getElementById('form-file');
    if (fileInput) {
        const dataTransfer = new DataTransfer();
        dataTransfer.items.add(file);
        fileInput.files = dataTransfer.files;
    }

    $upload.allowed = true;
  }

  handleSubmit(event) {
    var $upload = this;

    if (!$upload.file || !$upload.posterUrl) {
      alert(
        "Veuillez sélectionner une image avant de soumettre / Please select an image before submitting."
      );
      return;
    }

    event.preventDefault();
  }
}

UploadPosterController.$inject = [
  "$scope",
  "$uibModal",
  "$http",
  "$timeout",
  "fileUploadService",
];
