export default class VideosController {
  constructor($scope, $uibModal, $http, $timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;

    var ctrl = this;
    ctrl.offset = 20;
    ctrl.limit  = 20;
  }


  more(mode, salt, limit, offset){
    var ctrl = this;
    var params= {
        limit: limit || ctrl.limit,
        offset: offset || ctrl.offset,
        mode: mode
    }

    $("#see-more").hide();

    if(typeof(salt) != "undefined"){
      params.salt = salt;
    }
      ctrl.$http({
            url: '/json/films',
            params: params,
      }).then(function(resp, status, headers){
        $("#top50").append(resp.data);
        ctrl.offset = ctrl.offset + ctrl.limit;

        if(resp.data.trim() != "" && resp.headers()["x-has-more"] == "1"){
          $("#see-more").show();
        }

        if(resp.headers()["x-list-title"]){
          $("#film-list-title").html(resp.headers()["x-list-title"]);
        }
      })
  }

};

VideosController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
