const STATUS_INITIAL = 0,
      STATUS_HANDSHAKE = 10,
      STATUS_SAVING = 1,
      STATUS_CHECKING = 15,
      STATUS_SUCCESS = 2,
      STATUS_FAILED = 3;

export default class UploadController {

    constructor($scope, $uibModal, $http, $timeout, fileUploadService){
      this.$scope = $scope;
      this.$uibModal = $uibModal;
      this.$http = $http;
      this.$timeout = $timeout;
      this.fileUploadService = fileUploadService;
      var $upload = this;

      $upload.baseLang = document.location.pathname.substr(0,3) == "/en" ? "/en" : "";

      $upload.currentStatus = null;

      $upload.allowed = false;
      $upload.progress = 0;
      $upload.file = null;
      $upload.processing = false;
      $upload.error_code = '0';

      var dropbox = angular.element(".upload-form")[0];
      dropbox.addEventListener("dragenter", $upload.cancelEvent, false);
      dropbox.addEventListener("dragexit", $upload.cancelEvent, false);
      dropbox.addEventListener("dragover", $upload.cancelEvent, false);
      dropbox.addEventListener("drop", $upload.droppedFile, false);

    }

    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    }
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    }
    isHandshake() {
      return this.currentStatus === STATUS_HANDSHAKE;
    }
    isChecking() {
      return this.currentStatus === STATUS_CHECKING;
    }
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    }
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    }

    cancelEvent(e){
      e.stopPropagation();
      e.preventDefault();
    }


    droppedFile(evt){
      var $upload = this;
      $upload.cancelEvent(evt)
      var files = evt.dataTransfer.files;
      var count = files.length;
      if(files.length > 1){
        $upload.$timeout(function(){
          $upload.error_code = 'too_many_files';
        });
        return
      }
      $upload.$timeout(function(){
        $upload.fileChosen(files[0]);
      });
    }

    fileChosen(file, $upload) {
      var $upload = this;

      $upload.file = file;
      var filename = $upload.file.name;
      var fileext = filename.substr(filename.lastIndexOf('.')).toLowerCase();

      console.log($upload.file, 'chosen');

      $upload.error_code = null;
      $upload.error_message = null;
      $upload.progress = 0;

      var allowedExtensions = ['.mov', '.avi', '.m4v', '.mp4', '.mpeg2', '.mpeg4'];
      if(allowedExtensions.indexOf(fileext) == -1){
        $upload.error_code = 'file_ext';
        console.error($upload.error_code)
        return;
      }
      if ($upload.file.size > 2*1024*1024*1024){ // 2 Go
        $upload.error_code = 'file_size';
        console.error($upload.error_code)
        return;
      }
      $upload.allowed = true;
    }

    sendVideo() {
      var $upload = this;
      $upload.processing = true;

      const filecomponents = $upload.file.name.split('.');
      const extension = filecomponents.pop();

      var formData = new FormData();
      formData.append('extension', extension);

      $upload.fileUploadService.initiate($upload.video_id, formData)
        .then(result => {
            $upload.currentStatus = STATUS_SAVING;
            return $upload.fileUploadService.uploadMultipart(result.multipart_keys, $upload.file, x => {$upload.progress = x;});
        }).then(() => {
            $upload.currentStatus = STATUS_CHECKING;
            return $upload.fileUploadService.check($upload.video_id);
        }).then(() => {
            $upload.currentStatus = STATUS_SUCCESS;
            location.href = $upload.baseLang+'/profil/video/upload/confirm/'+$upload.video_id;
        }).catch(errorData => {
            document.getElementById("form-file").value="";
            $upload.file = null;
            $upload.processing = false;
            $upload.error_code = 'custom';
            $upload.allowed = false;
            $upload.currentStatus = STATUS_FAILED;

            console.log(errorData);
            if (typeof errorData === 'object' && errorData.status && errorData.status.toString().substring(0, 1) === '5') {
              $upload.error_message = 'Une erreur est survenue (HTTP ' + errorData.status + ')';
            } else if (errorData.status && errorData.status === -1) {
              $upload.error_message = 'Une erreur inconnue est survenue (network fail)';
            } else {
              $upload.error_message = typeof errorData.data?.error !== 'undefined' ? errorData.data.error : 'Une erreur inconnue est survenue (' + errorData.status + ')';
            }
        });
    }
};

UploadController.$inject = ['$scope', '$uibModal', '$http', '$timeout', 'fileUploadService'];
