export default class FaqController { 
    constructor($scope, $uibModal, $http, $timeout){
      var ctrl = this;
      $scope.toggleQuestion = function(id){
        if($scope.currentQuestion != id){
          $scope.currentQuestion = id;
        }else{
          $scope.currentQuestion = null;
        }
      }
    }
}

FaqController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];