export default class VideoController {
  constructor($scope, $uibModal, $http, $timeout){
    this.$scope = $scope;
    this.$uibModal = $uibModal;
    this.$http = $http;
    this.$timeout = $timeout;

    var ctrl = this;
    ctrl.supportError = "";
  }

  openShareEmbedModal(){
    var ctrl = this;
    ctrl.$uibModal.open({
      animation: false,
      templateUrl: 'share-video.html',
      windowClass: "archive",
      size:"md",
      controller: ["$uibModalInstance", "$scope", function($uibModalInstance, $scope) {
        $scope.cancel = function(){
          $uibModalInstance.dismiss();
        }
      }]
    });
  }

  login(redirectTo){
    document.location.href = '/profil/login?target_path='+redirectTo;
  }

  support(video_id){
    var ctrl = this;
    ctrl.supportError = "";
    ctrl.$http({
      method  : 'POST',
      url     : '/ajax/video/'+video_id+'/support'
    })
    .then(function(response){
      ctrl.buttonText = response.data.message;
      var el = angular.element("button.support");
      el.attr("disabled", "true");
      angular.element('.tooltip-vote').remove();
    })
    .catch(function(errorResponse){
      var error = errorResponse.data;
      ctrl.supportError = error.message;
    })
  }
};

VideoController.$inject = ['$scope', '$uibModal', '$http', '$timeout'];
