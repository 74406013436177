export default class ArchiveIndexController {
  constructor($scope){
    $scope.selectedYear = document.querySelector('.archive-item.selected').dataset.year; // Initial state
    $scope.selectedTrigger = document.querySelector('.editions .year.selected'); // Initial state

    $scope.selectYear = function($event, year){
      $scope.selectedTrigger.classList.remove('selected');
      $scope.selectedTrigger = $event.currentTarget;
      $scope.selectedTrigger.classList.add('selected');

      document.body.querySelector('.archive-item[data-year="' + $scope.selectedYear + '"]').style.display = 'none';
      $scope.selectedYear = year;
      document.body.querySelector('.archive-item[data-year="' + $scope.selectedYear + '"]').style.display = 'block';
    }
  }
}

ArchiveIndexController.$inject = ['$scope'];
